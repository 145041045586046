import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

// export const DOG_HOST_URL = "http://localhost:9999";
export const ISDID_SYSTEM = true;  // true=com DID,false=org
export const DOG_HOST_URL =  "https://newcryptospace.com";
// export const DOG_HOST_URL =  "http://0xtest2024pe.allpoints.space";
export const TX_CLOSE = true;
export const IS_TEST = true;
export const MAIN_TAG = 'Ethscriptionsnew';

export const SOLANA_CLOSE = false;

export const TW_LOGIN_URL = DOG_HOST_URL + "/ethscription/twitter/twitterLogin";

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_APPROVAL_AMOUNT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';


export const ETHS_URL = "https://ethscriptions.com/ethscriptions/";
export const IPFS_URL = "https://ipfs.decentralized-content.com/ipfs/";

//0x2d836D99E2222E160fC64c386eB80Cc9698e9AfA ，0xC346462828C2193899009e4616cbF5DeE7724Dcc
export const X20_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x2BB91086690E3b8355AE37692F839BE8A887e0b9',
  [SupportedChainId.BASE]: '0xf678382a37f3dda74dd2b76b2c8c4288ea083710',
  [SupportedChainId.WORLD]: '0x4f0259335bc22e11ddaf1379937384ad1f105949',
  [SupportedChainId.MAINNET]: '0x9787f0e36a37f8c54d7a80f8db9a24ff59ee0419',
}

export const SWAP_V2_ROUTER_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
  [SupportedChainId.BASE]: '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
  [SupportedChainId.WORLD]: '0x541aB7c31A119441eF3575F6973277DE0eF460bd',
  [SupportedChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
}


export const BASE_TOKEN_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x9842989969687f7d249d01Cae1D2ff6b7b6b6D35',
  [SupportedChainId.BASE]: '0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed',
  [SupportedChainId.WORLD]: '0x2cFc85d8E48F8EAB294be644d9E25C3030863003',
  [SupportedChainId.MAINNET]: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
}


export const SHARE_MANAGER_ADDRESSES: AddressMap = {
  //ARB正式 0x70b8FdA312ccB8a8B44c458276779f6dd378B517  ARBTEST 0xf57C904510c5f76279FC360A1F9BE15e595ebC5E  0x888F4408aB925Bd9511eA8440121186519fb4963 老的
  [SupportedChainId.ARBITRUM_ONE]: '0x70b8FdA312ccB8a8B44c458276779f6dd378B517',
  [SupportedChainId.POLYGON]: '0x7AD1308Fc58443b7E5fa07d03E19C427D5bFD417',
  [SupportedChainId.MAINNET]: '0x888F4408aB925Bd9511eA8440121186519fb4963',
  [SupportedChainId.ROPSTEN]: '0x888F4408aB925Bd9511eA8440121186519fb4963',
  [SupportedChainId.GOERLI]: '0x888F4408aB925Bd9511eA8440121186519fb4963',
  [SupportedChainId.RINKEBY]: '0x888F4408aB925Bd9511eA8440121186519fb4963',
}

export const ENS_REGISTRY_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
  [SupportedChainId.POLYGON]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
  [SupportedChainId.MAINNET]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
  [SupportedChainId.ROPSTEN]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
  [SupportedChainId.GOERLI]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
  [SupportedChainId.RINKEBY]: '0xC4C01738D9735467a7660cb99a9a1794ef47C625',
}
export const PUBLIC_RESOLVER_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
  [SupportedChainId.POLYGON]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
  [SupportedChainId.MAINNET]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
  [SupportedChainId.ROPSTEN]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
  [SupportedChainId.GOERLI]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
  [SupportedChainId.RINKEBY]: '0x06eA36605bA29FeA952A9c5Ee964cfa3083Ae4a0',
}
export const BASE_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
  [SupportedChainId.POLYGON]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
  [SupportedChainId.MAINNET]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
  [SupportedChainId.ROPSTEN]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
  [SupportedChainId.GOERLI]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
  [SupportedChainId.RINKEBY]: '0x022a7349df167D4c5A84C3374d32171fAda7Ed8d',
}
export const REVERSE_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
  [SupportedChainId.POLYGON]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
  [SupportedChainId.MAINNET]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
  [SupportedChainId.ROPSTEN]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
  [SupportedChainId.GOERLI]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
  [SupportedChainId.RINKEBY]: '0x5Cc34f19D01b9BE769f599230D4FE950146A7325',
}
export const ENS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
  [SupportedChainId.POLYGON]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
  [SupportedChainId.MAINNET]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
  [SupportedChainId.ROPSTEN]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
  [SupportedChainId.GOERLI]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
  [SupportedChainId.RINKEBY]: '0x66d0295De5A0c3EE26Ec82e2cc42103A3a1241C8',
}
export const CRYPTO_TOKEN_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xb68f4027406bA3D620816294F984057f40114772',
  [SupportedChainId.POLYGON]: '0xb68f4027406bA3D620816294F984057f40114772',
  [SupportedChainId.MAINNET]: '0xb68f4027406bA3D620816294F984057f40114772',
  [SupportedChainId.ROPSTEN]: '0xb68f4027406bA3D620816294F984057f40114772',
  [SupportedChainId.GOERLI]: '0xb68f4027406bA3D620816294F984057f40114772',
  [SupportedChainId.RINKEBY]: '0xb68f4027406bA3D620816294F984057f40114772',
}

//MATIC
export const  ONE_YEAR = 365 * 24 * 60 * 60;
export const ADD_SECRET ='0x0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF'
// export const PRICE_ORACLE ='0xf7d1cf37Bce96408C39Cb516438e548E0b72c91f'

export const close=false;
export const ENS_END= close ? "" : ".new"

//ARB NEW PUB 正式
export const ENS_REGISTRY ='0x5BF5be70D4Ed30E2333650D8AC593496302154e1'
export const PUBLIC_RESOLVER='0xBb8d22EEbA2085EB18455e6cAd5A276EF0D292Eb'
export const BASE_REGISTRAR_NFT='0xE3Aabc41e75d920486e3fae94d66FB6A0387fe5d'
export const REVERSE_REGISTRAR='0x14a8A66D1a3b6a7E8d296AC217D03500baCfe697'
export const REGIST_PRICE='0x447744244894dc61495666F8B39ACb5241b98189'
export const TOKEN_PRICE_ORACLE='0x9a102a2B21AB66a693BAEd1935f9e551CAEB7069'
// export const ENS_CONTROLLER='0x1e3316Bfd5B610dcE4BedD3CD1375C3844f4A9B9'//老的
export const ENS_CONTROLLER='0x7A0F98ab0Dc2B741CfA10Ba69E86cC55E520A570'
export const SHARE_MANAGER='0x70b8FdA312ccB8a8B44c458276779f6dd378B517'
export const CRYPTO_TOKEN ='0x9842989969687f7d249d01Cae1D2ff6b7b6b6D35'//PUB
export const SHORT_CONTROLLER ='0x31E8434730715e77fB47e7963B3Fe9B67e81DDC9'//PUB

export const DAO_NFT ='0x83e0cd486ba1939098522b3432285fea0f766efd'//PUB

export const DID_NFT ='0x3468496192bcAC12Ace2961517dd5B5e66D90CE1'//PUB
export const DID_FEE_MANAGER ='0xf02d59508a572020201545918dea51f29cc182e2'//PUB
// export const DID_FEE_MANAGER ='0xF0C68bD1213AC960884D5FcFcB4Fbda264Bd1d11'//PUB
export const TEST_POLYGIN_DID_NFT ='0x7E73bD11979C497Ae65604Baa755eF5A8ca141D9'//POLYGIN TEST
export const NFT_TO_BTC_UTILS ='0x4DC2D6A3a3c51D398a69944E26Ff1194fD2aba4c'//PUB
// export const NFT_TO_BTC_UTILS ='0x178dde479bea1e02d46fcce2bd7f5abb1940b146'// TEST with eth balance
// export const NFT_BTC_TEST ='0x055d2038c90C5Dd8e8984df6711a8FdEa0855808'//POLYGIN TEST



export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')

// celo v3 addresses
const CELO_V3_CORE_FACTORY_ADDRESSES = '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc'
const CELO_V3_ROUTER_ADDRESS = '0x5615CDAb10dc425a742d643d949a7F474C01abc4'
const CELO_V3_MIGRATOR_ADDRESSES = '0x3cFd4d48EDfDCC53D3f173F596f621064614C582'
const CELO_MULTICALL_ADDRESS = '0x633987602DE5C4F337e3DbF265303A1080324204'
const CELO_QUOTER_ADDRESSES = '0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8'
const CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x3d79EdAaBC0EaB6F08ED885C05Fc0B014290D95A'
const CELO_TICK_LENS_ADDRESSES = '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
  [SupportedChainId.CELO]: CELO_V3_ROUTER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_ROUTER_ADDRESS,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.BASE]: '0x3d66DAb54F4994A35DA4A57A5c3c97d79329C6D1',
  [SupportedChainId.WORLD]: '0xcDcB127Ce2b5043fe0b5622250d6A7713f7Aa153',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.CELO]: CELO_MULTICALL_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_MULTICALL_ADDRESS,
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
  [SupportedChainId.CELO]: CELO_V3_ROUTER_ADDRESS,
  [SupportedChainId.CELO_ALFAJORES]: CELO_V3_ROUTER_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_QUOTER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.CELO]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.CELO]: CELO_TICK_LENS_ADDRESSES,
  [SupportedChainId.CELO_ALFAJORES]: CELO_TICK_LENS_ADDRESSES,
}
