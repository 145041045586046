import { Contract } from '@ethersproject/contracts'
import QuoterV2Json from '@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import IUniswapV2PairJson from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import IUniswapV2Router02Json from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import QuoterJson from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json'
import TickLensJson from '@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json'
import UniswapInterfaceMulticallJson
  from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import NonfungiblePositionManagerJson
  from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import V3MigratorJson from '@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json'
import { useWeb3React } from '@web3-react/core'
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import EIP_2612 from 'abis/eip_2612.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import { ArgentWalletDetector, EnsPublicResolver, EnsRegistrar, Erc1155, Erc20, Erc721, Weth } from 'abis/types'
import WETH_ABI from 'abis/weth.json'
import SHARE_MANAGER_ABI from 'abis/ShareManager.json'
import ENS_REGISTRY_ABI from 'abis/ENS/ENSRegistry.json'
import ENS_CONTROLLER_ABI from 'abis/ENS/ETHRegistrarController.json'
import SHORT_CONTROLLER_ABI from 'abis/ENS/ShortRegistrarController.json'
import PUBLIC_RESOLVER_ABI from 'abis/ENS/PublicResolver.json'
import BASE_REGISTRAR_ABI from 'abis/ENS/BaseRegistrar.json'
import REVERSE_REGISTRAR_ABI from 'abis/ENS/ReverseRegistrar.json'
import TOKEN_PRICE_ORACLE_ABI from 'abis/ENS/TokenPriceOracle.json'
import REGIST_PRICE_ORACLE_ABI from 'abis/ENS/RegistPriceOracle.json'
import DID_NFT_ABI from 'abis/ENS/DID_NFT_ABI.json'
import FEE_MANAGER_ABI from 'abis/ENS/FEE_MANAGER_ABI.json'
import X20_FACTORY_ABI from 'abis/x20/X20_FACTORY_ABI.json'
import X20_TOKEN_ABI from 'abis/x20/X20_TOKEN_ABI.json'
import X20_POOL_ABI from 'abis/x20/X20_POOL_ABI.json'
import SWAP_ROUTER_ABI from 'abis/x20/SWAP_ROUTER_ABI.json'

import NFT_TO_BTC_UTILS_ABI from 'abis/NFT_TO_BTC_UTILS.json'

import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  BASE_REGISTRAR_NFT,
  BASE_TOKEN_ADDRESSES,
  CRYPTO_TOKEN,
  DAO_NFT,
  DID_FEE_MANAGER,
  DID_NFT,
  ENS_CONTROLLER,
  ENS_REGISTRAR_ADDRESSES,
  ENS_REGISTRY,
  MULTICALL_ADDRESS,
  NFT_TO_BTC_UTILS,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  PUBLIC_RESOLVER,
  QUOTER_ADDRESSES,
  REGIST_PRICE,
  REVERSE_REGISTRAR,
  SHARE_MANAGER_ADDRESSES,
  SHORT_CONTROLLER, SWAP_V2_ROUTER_ADDRESSES,
  TICK_LENS_ADDRESSES,
  TOKEN_PRICE_ORACLE,
  V2_ROUTER_ADDRESS,
  V3_MIGRATOR_ADDRESSES,
  X20_FACTORY_ADDRESSES
} from 'constants/addresses'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useMemo } from 'react'
import { NonfungiblePositionManager, Quoter, QuoterV2, TickLens, UniswapInterfaceMulticall } from 'types/v3'
import { V3Migrator } from 'types/v3/V3Migrator'

import { getContract } from '../utils'
import { SupportedChainId } from '../constants/chains'
import { degen, logo, world,shib } from '../assets'

const { abi: IUniswapV2PairABI } = IUniswapV2PairJson
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json
const { abi: QuoterABI } = QuoterJson
const { abi: QuoterV2ABI } = QuoterV2Json
const { abi: TickLensABI } = TickLensJson
const { abi: MulticallABI } = UniswapInterfaceMulticallJson
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
const { abi: V2MigratorABI } = V3MigratorJson

export function useShareManagerContract():  Contract | null {
  const { chainId } = useWeb3React()
  const address = chainId ? SHARE_MANAGER_ADDRESSES[chainId] : undefined
  return useContract(address, SHARE_MANAGER_ABI) as Contract | null
}

// export function useCryptoToken():  Contract | null {
//   const { chainId } = useWeb3React()
//   const address = chainId ? CRYPTO_TOKEN_ADDRESSES[chainId] : undefined
//   return useContract(address, SHARE_MANAGER_ABI) as Contract | null
// }

export function useCryptoToken() {
  return useTokenContract(CRYPTO_TOKEN, true);
}


export function useENS() {
  return useContract(ENS_REGISTRY, ENS_REGISTRY_ABI);
}

export function useController() {
  return useContract(ENS_CONTROLLER, ENS_CONTROLLER_ABI);
}
export function useShortController() {
  return useContract(SHORT_CONTROLLER, SHORT_CONTROLLER_ABI);
}
export function usePublicResolver() {
  return useContract(PUBLIC_RESOLVER, PUBLIC_RESOLVER_ABI);
}
export function useBaseRegistrarNft() {
  return useContract(BASE_REGISTRAR_NFT, BASE_REGISTRAR_ABI);
}
export function useReverseRegistrar() {
  return useContract(REVERSE_REGISTRAR, REVERSE_REGISTRAR_ABI);
}
export function useTokenPriceOracle() {
  return useContract(TOKEN_PRICE_ORACLE, TOKEN_PRICE_ORACLE_ABI);
}
export function useRegistPriceOracle() {
  return useContract(REGIST_PRICE, REGIST_PRICE_ORACLE_ABI);
}

export function useDidNft() {
  return useContract(DID_NFT, DID_NFT_ABI);
}
export function useDidFeeManager() {
  return useContract(DID_FEE_MANAGER, FEE_MANAGER_ABI);
}
export function useNftToBtcUtils() {
  return useContract(NFT_TO_BTC_UTILS, NFT_TO_BTC_UTILS_ABI);
}

export function useDaoStandNft() {
  return useContract(DAO_NFT, DID_NFT_ABI);
}

export function useX20Factory() {
  const { chainId } = useWeb3React()
  // @ts-ignore
  const address = X20_FACTORY_ADDRESSES[chainId];
  return useContract(address, X20_FACTORY_ABI);
}

export function getSwapV2RouterAddress() {
  try{
    const { chainId } = useWeb3React()
    // @ts-ignore
    return SWAP_V2_ROUTER_ADDRESSES[chainId];
  }catch(e){console.error(e)}
}

export function useSwapV2Router() {
  const { chainId } = useWeb3React()
  // @ts-ignore
  const address = SWAP_V2_ROUTER_ADDRESSES[chainId];
  return useContract(address, SWAP_ROUTER_ABI);
}

export function getBaseToken() {
  try{
    const { chainId } = useWeb3React()
    // @ts-ignore
    return BASE_TOKEN_ADDRESSES[chainId];
  }catch(e){console.error(e)}
}

export const MAX_USER = 100;

export function getBaseTokenName() {
    const { chainId } = useWeb3React()
    let tokenNameValue = '';
    if(chainId === SupportedChainId.BASE){
      tokenNameValue = "DEGEN";
    }else  if(chainId === SupportedChainId.MAINNET){
      tokenNameValue = "SHIB";
    }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
      tokenNameValue = "CRYPTO";
    }else  if(chainId === SupportedChainId.WORLD){
      tokenNameValue = "WLD";
    }
    return tokenNameValue;
}

export function getChainName() {
  const { chainId } = useWeb3React()
  let chain = '';
  if(chainId === SupportedChainId.BASE){
    chain = 'BASE';
  }else  if(chainId === SupportedChainId.MAINNET){
    chain = 'ETH';
  }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
    chain = 'ARB';
  }else  if(chainId === SupportedChainId.WORLD){
    chain = 'WORLD';
  }
  console.info("getChainName,chainId=" + chainId + ", chain=" + chain);
  return chain;
}

export function getBaseTokenImage() {
  const { chainId } = useWeb3React()
  let tokenNameValue = '';
  if(chainId === SupportedChainId.BASE){
    tokenNameValue = degen;
  }else  if(chainId === SupportedChainId.MAINNET){
    tokenNameValue = shib;
  }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
    tokenNameValue = logo;
  }else  if(chainId === SupportedChainId.WORLD){
    tokenNameValue = world;
  }
  return tokenNameValue;
}

export function useBaseToken() {
  return useContract(getBaseToken(), X20_TOKEN_ABI);
}

export function upFirst(string: string): string {
  if (!string) return ""; // Handle empty or null input
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function useX20Token(tokenAddr: string) {
  return useContract(tokenAddr, X20_TOKEN_ABI);
}

export function useX20Pool(poolAddr: string) {
  return useContract(poolAddr, X20_POOL_ABI);
}


// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

export function useV2MigratorContract() {
  return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useV2RouterContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESS, IUniswapV2Router02ABI, true)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESS, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  )
}

export function useQuoter(useQuoterV2: boolean) {
  return useContract<Quoter | QuoterV2>(QUOTER_ADDRESSES, useQuoterV2 ? QuoterV2ABI : QuoterABI)
}

export function useTickLens(): TickLens | null {
  const { chainId } = useWeb3React()
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined
  return useContract(address, TickLensABI) as TickLens | null
}
