import React, { useState, useEffect, useCallback } from 'react'

import "./InfoSwap.css";
import {
  getBaseToken,
  getBaseTokenImage,
  getBaseTokenName, getSwapV2RouterAddress,
  useBaseToken, useSwapV2Router,
  useX20Token
} from '../../../../hooks/useContract'
import useInterval from '../../../../hooks/useInterval'
import { useWeb3React } from '@web3-react/core'
import {
  getBalanceNumber,
  getBalanceNumber2,
  getBalanceNumber3,
  getDisplayBalance, getMinBalance, getShowBalance,
  toBigString
} from '../../../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import useTokenApproves from '../../../../hooks/useTokenApproves'
import { message } from 'antd'
import SwappingModal from '../../../../components/FeatureFlagModal/SwappingModal'

interface SwapProps {
  address: string;
  name: string;
  logo: string;
}

const InfoSwap = (props:SwapProps) => {
  const tokenAddress = props.address;
  // const tokenAddress = '0x4ec3b7Ada5f8508CB0a27260E3002AA1b401F067';
  // console.info(tokenAddress);
  const baseTokenAddr = getBaseToken();
  const baseTokenName = getBaseTokenName();
  const baseTokenImage = getBaseTokenImage();
  const { account, chainId } = useWeb3React()
  const x20Token = useX20Token(tokenAddress);
  const baseToken = useBaseToken();
  const swapV2Router = useSwapV2Router();
  const swapV2RouterAddress = getSwapV2RouterAddress();
  const [sellTokenAddress, setSellTokenAddress] = useState('');

  const {doApproves} = useTokenApproves(sellTokenAddress, swapV2RouterAddress);

  const [baseAllowance, setBaseAllowance] = useState('0');
  const [baseBalance, setBaseBalance] = useState('0');

  const [tokenAllowance, setTokenAllowance] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');

  const [tab, settab] = useState(0);

  const [buyAmount, setBuyAmount] = useState('');
  const buyAmountChange = ({ target: { value } }) => {
    setBuyAmount(value);
  };

  const [sellAmount, setSellAmount] = useState('');
  const sellAmountChange = ({ target: { value } }) => {
    setSellAmount(value);
  };

  const [defaultValues, setDefaultValues] = useState([
    {
      name: "reset",
      value: "0.0",
    },
    {
      name: "0.1 SOL",
      value: "0.1",
    },
    {
      name: "0.5 SOL",
      value: "0.5",
    },
    {
      name: "1 SOL",
      value: "1",
    },
  ]);

  useEffect(() => {
    if(tab === 0){
      setSellTokenAddress(baseTokenAddr);
    }else{
      setSellTokenAddress(tokenAddress);
    }
  }, [tab, setSellTokenAddress, baseTokenAddr, tokenAddress]);

  const fetchLastTime = useCallback(async () => {
    try{
      if(account){
        if(baseToken && swapV2RouterAddress){
          const allowance = getBalanceNumber(new BigNumber((await baseToken.allowance(account, swapV2RouterAddress)).toString()));
          setBaseAllowance(allowance);
          const balance = getBalanceNumber(new BigNumber((await baseToken.balanceOf(account)).toString()));
          setBaseBalance(balance);
          // console.info('base token Allowance=' + allowance + ' ,balance=' + balance);
        }
        if(x20Token && swapV2RouterAddress){
          const allowance = getBalanceNumber(new BigNumber((await x20Token.allowance(account, swapV2RouterAddress)).toString()));
          setTokenAllowance(allowance);
          const balance = getBalanceNumber(new BigNumber((await x20Token.balanceOf(account)).toString()));
          setTokenBalance(balance);
          // console.info('x20 token Allowance=' + allowance + ' ,balance=' + balance);
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [  account, tokenAddress, baseToken, swapV2RouterAddress, setBaseAllowance, x20Token, setTokenAllowance, setBaseBalance, setTokenBalance]);
  useInterval(fetchLastTime);


  const [showSwappingDialog, setShowSwappingDialog] = useState(false);
  const [sendSwappingHash, setSendSwappingHash] = useState('');


  function showSwappingHash(hash: string) {
    setSendSwappingHash(hash)
    setShowSwappingDialog(true)
  }

  function onSwappingBack() {
    setSendSwappingHash('')
    setBuyAmount('');
    setSellAmount('');
    setShowSwappingDialog(false)
  }


  const handleSwap = useCallback(async () => {
    if(!account){return message.error('please connect wallet first');}
    const isBuy = tab === 0;
    try{
      if(isBuy){
        if(parseFloat(buyAmount) <= 0){
          return message.error('The purchase quantity must be a positive number.');
        }
        if(parseFloat(buyAmount) > parseFloat(baseAllowance)){
          doApproves().then(r => {});
          return false;
        }
        if(parseFloat(buyAmount) > parseFloat(baseBalance)){
          return message.error('Insufficient token balance.');
        }
      }else{
        if(parseFloat(sellAmount) <= 0){
          return message.error('The sale quantity must be a positive number.');
        }
        if(parseFloat(sellAmount) > parseFloat(tokenAllowance)){
          doApproves().then(r => {});
          return false;
        }
        if(parseFloat(sellAmount) > parseFloat(tokenBalance)){
          return message.error('Insufficient token balance.');
        }
      }

      if(swapV2Router){
        // console.info('burn token==>' + swapV2Router);

        const amountValue = isBuy ? buyAmount : sellAmount;
        const amount = toBigString(amountValue.toString());
        const path = isBuy ? [baseTokenAddr, tokenAddress] : [tokenAddress, baseTokenAddr];
        const timestampInSeconds = Math.floor(Date.now() / 1000) + 1200;

        // const amountsOut = await swapV2Router.getAmountsOut(amount, path);
        // console.info('amountsOut==');
        // console.info(amountsOut);

        // console.info('normalValue==' + getBalanceNumber2(amountsOut[1], 18));
        // const minValue = getBalanceNumber3(amountsOut[1], 2);
        // console.info('minValue==' + getBalanceNumber2(minValue, 18));
        const minValue = '0';
        await swapV2Router.swapExactTokensForTokensSupportingFeeOnTransferTokens(amount, minValue, path, account, timestampInSeconds).then(r => {
          showSwappingHash(r.hash);
        });
      }else{
        console.error('factory not init');
      }
    }catch (e: any){
      console.error(e);
    }
  }, [swapV2Router, account, tab, buyAmount, sellAmount, tokenAddress, baseTokenAddr, baseAllowance, tokenAllowance,
    baseBalance, tokenBalance]);

  const setMaxValue = () => {
    if(tab === 0){
      setBuyAmount(baseBalance);
    }else{
      setSellAmount(tokenBalance);
    }
  }

  return (
    <div className="swapfixedcoin">
      <div className="swapfixedcoin_box">
        <div className="swap_tab">
          <div
            className={tab === 0 ? "from_btn btn_active" : "from_btn"}
            onClick={() => settab(0)}
          >
            Buy
          </div>
          <div
            className={tab === 1 ? "from_btn btn_active" : "from_btn"}
            onClick={() => settab(1)}
          >
            Sell
          </div>
        </div>

        {tab === 0 && (
          <div className="input_box">
            <div className="input_info">
              <input
                type="number"
                placeholder="0"
                className="input"
                value={buyAmount}
                onChange={buyAmountChange}
              />
              <img src={baseTokenImage} alt="" className="icon" />
            </div>
            {/*<div className="input_default">
              {defaultValues.map((item) => {
                return (
                  <div key={item.value + ' ' + baseTokenName} onClick={() => setBuyAmount(parseFloat(item.value))}>
                    {item.value + ' ' + baseTokenName}
                  </div>
                );
              })}
            </div>*/}
          </div>
        )}
        {tab === 1 && (
          <div className="input_box">
            <div className="input_info">
              <input
                type="number"
                placeholder="0"
                className="input"
                value={sellAmount}
                onChange={sellAmountChange}
              />
              <img src={props.logo} alt="" className="icon" />
            </div>
            {/*<div className="input_default">
              {defaultValues.map((item) => {
                return (
                  <div
                    key={item.value +' '+ props.name}
                    onClick={() => setSellAmount(parseFloat(item.value))}
                  >
                    {item.value +' '+ props.name}
                  </div>
                );
              })}
            </div>*/}
          </div>
        )}

        <div className="explain">
          <div>Balance: {getShowBalance(new BigNumber(tab === 0 ? baseBalance : tokenBalance))}</div>
          <div onClick={setMaxValue}>Max </div>
        </div>

        <div className="swap_btn" onClick={handleSwap}>
          {
            (tab === 0 && parseFloat(buyAmount) > parseFloat(baseAllowance)) || (tab === 1 && parseFloat(sellAmount) > parseFloat(tokenAllowance)) ? 'Approve' : (tab === 0 ? 'Buy' : 'Sell')
          }
        </div>
      </div>

      <SwappingModal open={showSwappingDialog} close={() => onSwappingBack()} hash={sendSwappingHash}  back={onSwappingBack}/>

    </div>
  );
};
export default InfoSwap;
