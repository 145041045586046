import { Image, message, Modal, Pagination, Table } from 'antd'
import React, {useCallback, useState} from 'react'
import './PubProfile.css'
import useInterval from '../../../hooks/useInterval'
import {BigNumber} from "bignumber.js";
import {getDisplayBalance} from "../../../utils/formatBalance";
import {useWeb3React} from "@web3-react/core";
import { MAX_USER, upFirst, useX20Factory } from '../../../hooks/useContract'
import {NavLink} from "react-router-dom";
import useCopyClipboard from '../../../hooks/useCopyClipboard'
import { SupportedChainId } from '../../../constants/chains'

const { Column } = Table;
const PubProfile = (props: any) => {
    const { account,chainId } = useWeb3React()
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const [tokenPoolInfo, setTokenPoolInfo] = useState(new Map());

    const [bornToken, setBornToken] = useState('');

    const [myJoinX20ProjectList, setMyJoinX20ProjectList] = useState([]);
    const [myJoinX20ProjectLogList, setMyJoinX20ProjectLogList] = useState([]);
    const [myX20TokenList, setMyX20TokenList] = useState([]);

    const x20Factory = useX20Factory();

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const onChange = (page: any) => {
        setCurrent(page);
    };

    const [currentLog, setCurrentLog] = useState(1);
    const [totalLog, setTotalLog] = useState(0)
    const onChangeLog = (page: any) => {
        setCurrentLog(page);
    };


    const getMyJoinInfo = useCallback(async () => {
        // const twToken = localStorage.getItem(ALL_POINT_LOGIN_KEY);
        if(!account)return;
        try{
            let chain = 'BASE';
            console.info(account + ' ,chainId= ' + chainId);
            if(chainId === SupportedChainId.BASE){
                chain = 'BASE';
            }else  if(chainId === SupportedChainId.MAINNET){
                chain = 'ETH';
            }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
                chain = 'ARB';
            }else  if(chainId === SupportedChainId.WORLD){
                chain = 'WORLD';
            }

            const list = await fetch(API_SERVER + '/v2api/x20/myAccount?address=' + account +'&chain=' + chain  +'&projPage=' + current +'&logPage=' + currentLog)
            const resList = await list.json()
            if (resList['code'] === 200) {
                const listRow = resList['data'];
                if(listRow['tweetUser']){
                    // setMyInfo(listRow['tweetUser']);
                    // setMyToken(listRow['tweetUser']['token']);
                }
                setMyJoinX20ProjectList(listRow['myJoinX20ProjectList']);
                setMyJoinX20ProjectLogList(listRow['myJoinX20ProjectLogList']);
                setMyX20TokenList(listRow['myTokens']);
                setTotal(listRow['totalProj']);
                setTotalLog(listRow['totalLog']);
            }
        }catch (e) {
            console.error(e);
        }
    }, [account, setMyJoinX20ProjectList, setMyJoinX20ProjectLogList, current, currentLog, setTotal, setTotalLog, chainId]);

    const getTokenList = useCallback(async () => {
        if(!account)return;
        try{
            // @ts-ignore
            const tokenList = await x20Factory.getJoin(account);
            console.info('tokenList=');
            console.info(tokenList);
            const rsMap = new Map();
            for (let i=0; i< tokenList.length; i++){
                const rewardInfoObj = tokenList[i];
                const available = getDisplayBalance(new BigNumber(rewardInfoObj.available.toString()));
                const userWithdraw = getDisplayBalance(new BigNumber(rewardInfoObj.userWithdraw.toString()));
                console.info('available=' + available + 'userWithdraw=' + userWithdraw);
                rsMap.set(rewardInfoObj.tokenAddress.toString().toLowerCase(), {available:available,userWithdraw:userWithdraw});
            }
            setTokenPoolInfo(rsMap);
        }catch (e) {
            console.error(e);
        }
    }, [account, setTokenPoolInfo, current, currentLog, setTotal, setTotalLog, x20Factory]);

    const fetchLastTime = useCallback(async () => {
        try {
            getMyJoinInfo();
            getTokenList();
        } catch (e) {
            console.log(e);
        }
    }, [account,  getMyJoinInfo, chainId,
        // getTokenList, cryptoToken
    ]);
    useInterval(fetchLastTime);


    const bornPool = useCallback(async () => {
        if(!account){return message.error('please connect wallet first');}
        dismissDialog();
        try{
            if(x20Factory){
                console.info('burn token==>' + bornToken);
                await x20Factory.bornPool(bornToken);
            }else{
                console.error('factory not init');
            }
        }catch (e: any){
            console.error(e);
        }
    }, [x20Factory, account, bornToken]);

    const [goonVisible, setGoonVisible] = useState(false);
    const showDialog = (token: string) => {
        setBornToken(token);
        setGoonVisible(true);
    };
    const dismissDialog = () => {
        setGoonVisible(false);
    };

    // @ts-ignore
    return (
        <>
        <div className="MyTokenList">
            <div className="history">
                <div className="table-list">
                    <Table id="domainTable" dataSource={myJoinX20ProjectList} pagination={false} >
                        <Column title="Logo" dataIndex="avatar" key="avatar"
                                render={(_: any, record: any) => (
                                  <div className="table_time">
                                      <Image
                                        width={20}
                                        className="rule_img"
                                        src={record.avatar}
                                      />
                                  </div>
                                )}
                        />
                        <Column title="Name" dataIndex="userName" key="userName"/>
                        <Column title="Symbol" dataIndex="nickName" key="nickName"/>
                        <Column title="Available for withdrawal" dataIndex="creator" key="creator"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {tokenPoolInfo.get(record.token) ? tokenPoolInfo.get(record.token)['available'] : 0}
                                    </div>
                                )}
                        />
                        <Column title="Have been withdrawn" dataIndex="creator" key="creator"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {tokenPoolInfo.get(record.token) ? tokenPoolInfo.get(record.token)['userWithdraw'] : 0}
                                    </div>
                                )}
                        />
                        <Column title="Withdrawal link" dataIndex="tokenAddress" key="tokenAddress"
                                render={(_: any, record: any) => (
                                    <div className="table_copy">
                                        {/*<a className="copy_btn" href={"/#/mintToken/"+record.token}>Mint</a>*/}
                                        <NavLink to={'/info?token=' + record.token}>
                                            <div className="to_withdraw">To Withdraw</div>
                                        </NavLink>
                                    </div>
                                )}
                        />
                    </Table>
                </div>
                <div className="pageDiv">
                    <Pagination
                        total={total}
                        defaultPageSize={10}
                        defaultCurrent={1}
                        current={current}
                        onChange={onChange}
                        showSizeChanger={false}
                    />
                </div>
            </div>


            <div className="history">
                <div className="type-title">Tokens I created</div>
                <div className="table-list">
                    <Table id="domainTable" dataSource={myX20TokenList} pagination={false} >
                        <Column title="Logo" dataIndex="name" key="name"
                                render={(_: any, record: any) => (
                                  <div className="table_time">
                                      <Image
                                        width={20}
                                        className="rule_img"
                                        src={record.avatar}
                                      />
                                  </div>
                                )}
                        />
                        <Column title="Name" dataIndex="userName" key="userName"
                                render={(_: any, record: any) => (
                                  <div className="table_time">
                                      <NavLink to={'/info?token=' + record.token}>
                                          <div className="contributors">{record.userName}</div>
                                      </NavLink>
                                  </div>
                                )}
                        />
                        <Column title="Symbol" dataIndex="nickName" key="nickName"/>
                        <Column title="Contributors" dataIndex="userTotal" key="userTotal"
                                render={(_: any, record: any) => (
                                  <div className="table_time">
                                      <NavLink to={'/log?token=' + record.token}>
                                          <div className="contributors">{record.userTotal}</div>
                                      </NavLink>
                                  </div>
                                )}
                        />
                        <Column title="Time" dataIndex="createTime" key="createTime"/>
                        <Column title="On chain" dataIndex="chain" key="chain"
                            render={(_: any, record: any) => (
                              <div className="contributors">{upFirst(record.chain.toLowerCase())}</div>
                            )}
                        />

                        <Column title="Cancel (burn)" dataIndex="token" key="token"
                                render={(_: any, record: any) => (
                                  parseFloat(record.userTotal) >= MAX_USER ? <div/> : <div className="table_copy">
                                          <div className="to_withdraw" onClick={() => showDialog(record.token)}>Burn
                                          </div>
                                      </div>
                                )}
                        />
                    </Table>
                </div>
                {/*<div className="pageDiv">
                    <Pagination
                        total={totalLog}
                        defaultPageSize={10}
                        defaultCurrent={1}
                        current={currentLog}
                        onChange={onChangeLog}
                        showSizeChanger={false}
                    />
                </div>*/}
            </div>

            <Modal
              visible={goonVisible}
              onCancel={dismissDialog}
              closable={false}
              centered={true}
              footer={null}
              className="CopyModal"
            >
                {/*<div className="ModalTitle">
                    Confirm to Copy the Invitation Link
                </div>*/}
                <div className="ModalContent">
                    Before the number of addresses that have contributed the equal amount of small liquidity reaches 100, the token creator can cancel, that is, burn the issued tokens. At the same time, all addresses that have contributed the equal amount of small liquidity will receive 90% refund of the $CRYPTO or $SHIB or $DEGEN or $WLD paid, and the system will deduct 10% of the $CRYPTO or $SHIB or $DEGEN or $WLD tokens paid as a refund tax. Only the token creator has the power to cancel (burn) the tokens he created. When the number of addresses that have contributed the equal amount of small liquidity is greater than 100, you will not be able to burn the tokens you created. Of course, if you have already burned the tokens you created, you will not be able to burn again.
                </div>
                <div className="ModalBtns">
                    <div className="OneBtn CancelBtn" onClick={dismissDialog}>I see</div>
                    <div className="OneBtn YesBtn" onClick={bornPool}>Burn</div>
                </div>
            </Modal>

        </div>
        </>
    )
}
export default PubProfile
